@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

html, body {
  width: 100%; /* Garante que o body e o html ocupem toda a largura da tela */
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Remove a rolagem horizontal */
  font-family: 'Poppins', sans-serif; /* Define Poppins como a fonte padrão */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
